import React from 'react';
import Printfoots from '../../assets/images/screen_3/printfootsway.svg';
import './style.scss';

const PrintfootsWay = () => (
  <section className="printfoots-way">
    <h2>Route</h2>
    <p>You will be working in The Amazing Orange County</p>
    <img src={Printfoots} alt="Printfoots" />
  </section>
);

export default PrintfootsWay;
