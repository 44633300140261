import React from 'react';
import PropTypes from 'prop-types';
import './imageList.scss';

const ImageList = ({
  image, check, title, secondTitle, list, flexType,
}) => {
  const renderList = () => (
    list.map(element => (
      <li key={element.id}>
        <img src={check} alt="check" />
        <p>{element.text}</p>
      </li>
    ))
  );

  const { secondText, specialText } = secondTitle;

  return (
    <section className={`image-list ${flexType}`}>
      <div className="image-container">
        <img src={image} alt="footprints" />
      </div>
      <article>
        <div>
          <h3>{title}</h3>
          {secondText !== '' ? (
            <h3>
              {secondText}
              <span>{specialText}</span>
            </h3>
          ) : ''}
          <ul>
            {renderList()}
          </ul>
        </div>
      </article>
    </section>
  );
};

ImageList.propTypes = {
  image: PropTypes.string.isRequired,
  check: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  list: PropTypes.instanceOf(Array).isRequired,
  secondTitle: PropTypes.objectOf(Object),
  flexType: PropTypes.string,
};

ImageList.defaultProps = {
  secondTitle: { secondText: '', specialText: '' },
  flexType: 'flex-normal',
};

export default ImageList;
