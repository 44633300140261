import React from 'react';
import Boxes from '../../assets/images/screen_2/boxes.svg';
import './style.scss';

const AboutUs = () => (
  <section className="about-us">
    <img src={Boxes} alt="Boxes" />
    <article>
      <h3>About Us!</h3>
      <p>
        We are a small to medium-size package delivery firm
        seeking customer obsessed, team-oriented Delivery/Driver.
      </p>
    </article>
  </section>
);

export default AboutUs;
