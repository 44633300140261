import React from 'react';
import Qualification from '../assets/images/screen_3/qualification.svg';
import Check from '../assets/images/screen_3/check.svg';
import ImageList from './shared/ImageList';

const Qualifications = () => {
  const qualifications = [
    { id: 1, text: 'Must be at least 21 years of age with a valid driver’s license' },
    { id: 2, text: 'Experience in delivery driving, customer service 1 year (Preferred)' },
    { id: 3, text: 'Ability to handle physical workload (moving in and out of a van up to 250 times)' },
    { id: 4, text: 'Must have 2 years of driving experience' },
    { id: 5, text: 'Ability to effectively communicate in English (Bilingual candidates are welcome to apply)' },
  ];

  return (
    <ImageList
      image={Qualification}
      check={Check}
      title="Qualifications:"
      list={qualifications}
      flexType="flex-reversed"
    />
  );
};

export default Qualifications;
