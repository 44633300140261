import React from 'react';
import FootprintCheck from '../assets/images/screen_3/footprints_check.svg';
import Check from '../assets/images/screen_3/check.svg';
import ImageList from './shared/ImageList';

const Benefits = () => {
  const benefits = [
    { id: 1, text: 'Dental Insurance' },
    { id: 2, text: 'Health Insurance' },
    { id: 3, text: 'Paid Time Off' },
    { id: 4, text: 'Referral Program' },
    { id: 5, text: 'Vision Insurance' },
  ];

  return (
    <ImageList
      image={FootprintCheck}
      check={Check}
      title="Why work with us?"
      secondTitle={{ secondText: 'You will have', specialText: 'a lot of benefits!' }}
      list={benefits}
    />
  );
};

export default Benefits;
