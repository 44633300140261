import React from 'react';
import Position from '../../assets/images/screen_1/position.svg';
import './style.scss';

const Footer = () => (
  <footer className="footer">
    <section>
      <h3>Contact us!</h3>
      <article>
        <h4>Doodle Deliveries</h4>
        <a href="https://goo.gl/maps/RzMGUCE3GKjRkCoN7" target="_new">
          <img src={Position} alt="Position" />
          Huntington Beach, CA, United States
        </a>
      </article>
      <article>
        <a className="email" href="mailto:careers@doodledrivers.com">
          careers@doodledrivers.com
        </a>
      </article>
    </section>
    <div>
      <h4>2020 Doodle Deliveries</h4>
      <h4>
        Powered by
        <a href="https://cyberhelpsolutions.com/" target="_new">
          Cyber Help Solutions
        </a>
      </h4>
    </div>
  </footer>
);

export default Footer;
