import React, { useRef, useEffect, useLayoutEffect } from 'react';
import City1 from '../../assets/images/screen_1/city1.svg';
import City2 from '../../assets/images/screen_1/city2.svg';
import Logo from '../../assets/images/screen_1/logo.svg';
import Car from '../../assets/images/screen_1/car.svg';
import Wheel from '../../assets/images/screen_1/wheel.svg';
import Position from '../../assets/images/screen_1/position.svg';
import ScrollBtn from '../shared/ScrollBtn';
import './style.scss';

const WelcomeSection = () => {
  const city1 = useRef(null);
  const city2 = useRef(null);
  const car = useRef(null);
  const wheel1 = useRef(null);
  const wheel2 = useRef(null);
  let flag = 0;
  let flag2 = 100;
  let flag3 = 0;

  const startAnimation = () => {
    setTimeout(() => {
      flag -= 0.2;
      flag2 -= 0.2;
      flag3 += 1;
      city1.current.style.transform = `translate(${flag}%)`;
      city2.current.style.transform = `translate(${flag2}%)`;
      wheel1.current.style.transform = `rotate(${flag3}deg)`;
      wheel2.current.style.transform = `rotate(${flag3}deg)`;

      if (flag < -100) {
        flag = 100;
      }
      if (flag2 < -100) {
        flag2 = 100;
      }
      if (flag3 === 360) {
        flag3 = 0;
      }

      startAnimation();
    }, 10);
  };

  useEffect(() => {
    startAnimation();
  });

  useLayoutEffect(() => {
    const onScroll = () => {
      let { scrollY } = window;
      const { innerWidth } = window;
      scrollY += 20;
      if (scrollY < innerWidth) {
        car.current.style.left = `${scrollY}px`;
      }
    };
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <section className="welcome-section">
      <header>
        <img id="logo" src={Logo} alt="logo" />
        <div className="position-container">
          <a href="https://goo.gl/maps/iBjN36TVY7WDBqQA6" target="_new">
            <img src={Position} alt="position" />
            Orange, County.
          </a>
        </div>
      </header>
      <article>
        <h1>Work with us!</h1>
        <ScrollBtn />
      </article>
      <div className="car-container" ref={car}>
        <img id="car" src={Car} alt="car" />
        <img id="wheel1" src={Wheel} alt="wheel" ref={wheel1} />
        <img id="wheel2" src={Wheel} alt="wheel" ref={wheel2} />
      </div>
      <img id="city1" src={City1} alt="city" ref={city1} />
      <img id="city2" src={City2} alt="city" ref={city2} />
    </section>
  );
};

export default WelcomeSection;
