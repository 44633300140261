import React from 'react';
import Responsibility from '../assets/images/screen_3/responsibility.svg';
import Check from '../assets/images/screen_3/check.svg';
import ImageList from './shared/ImageList';

const Responsibilities = () => {
  const responsibilities = [
    { id: 1, text: 'Deliver products to customer locations in a timely manner' },
    { id: 2, text: 'Load and unload vehicle' },
    { id: 3, text: 'Inspect and monitor delivery vehicle' },
    { id: 4, text: 'Maintain accurate inventory of packages and materials' },
  ];

  return (
    <ImageList
      image={Responsibility}
      check={Check}
      title="Responsibilities:"
      list={responsibilities}
    />
  );
};

export default Responsibilities;
