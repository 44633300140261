import React from 'react';
import Welcome from './welcome';
import AboutUs from './aboutus';
import WorkerMessage from './workermessage';
import Benefits from './Benefits';
import Qualifications from './Qualifications';
import Responsibilities from './Responsibilities';
import PrintfootsWay from './printfootsway';
import FormScreen from './form';
import Footer from './footer';

const App = () => (
  <div className="app">
    <Welcome />
    <AboutUs />
    <WorkerMessage />
    <Benefits />
    <Qualifications />
    <Responsibilities />
    <PrintfootsWay />
    <FormScreen />
    <Footer />
  </div>
);

export default App;
