import React from 'react';
import Worker1 from '../../assets/images/form/worker_1.svg';
import Worker2 from '../../assets/images/form/worker_2.svg';
import Worker3 from '../../assets/images/form/worker_3.svg';
import FormOk from '../../assets/images/form/formOk.svg';
import emailservice from '../../api/emailservice';
import './style.scss';

const validNameRegex = RegExp(
  /^[A-zÁ-ź .ñ']+$/,
);

const validEmailRegex = RegExp(
  /^[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z]{2,}$/,
);

const validPhoneRegex = RegExp(
  /^[0-9( )\-+]+$/,
);

class FormScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        name: { text: 'name', value: '', error: null },
        phone: { text: 'phone', value: '', error: null },
        email: { text: 'email', value: '', error: null },
      },
      error: null,
    };
    this.form = React.createRef();
    this.formBtn = React.createRef();
    this.contImgOk = React.createRef();
  }

  cleanForm = () => {
    this.setState({
      form: {
        name: { text: 'name', value: '', error: null },
        phone: { text: 'phone', value: '', error: null },
        email: { text: 'email', value: '', error: null },
      },
    });
  }

  checkErrorForm = (name, value) => {
    let error;

    switch (name) {
      case 'name':
        error = validNameRegex.test(value)
          ? null
          : 'Full name is not valid!';
        break;
      case 'phone':
        error = validPhoneRegex.test(value)
          ? null
          : 'Phone number is not valid!';
        break;
      case 'email':
        error = validEmailRegex.test(value)
          ? null
          : 'Email is not valid!';
        break;
      default:
        break;
    }

    this.setState(state => ({
      form: { ...state.form, [name]: { text: name, value, error } },
    }));

    return error ? 1 : 0;
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.checkErrorForm(name, value);
  }

  checkFormErros = form => {
    let errors = 0;
    Object.values(form).forEach(e => {
      errors += this.checkErrorForm(e.text, e.value);
    });
    return errors;
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.formBtn.current.disabled = true;
    const { form } = this.state;
    const { name, phone, email } = form;
    const errors = await this.checkFormErros(form);
    if (errors === 0) {
      emailservice.post('api/sendMail', {
        to: 'careers@doodledrivers.com',
        company_name: 'Doodle Deliveries',
        name: name.value,
        phone_number: phone.value,
        email: email.value,
        secret_key: process.env.REACT_APP_SERVICE_EMAIL_API,
      })
        .then(res => {
          if (res.data === 'Email sent') {
            this.cleanForm();
            this.form.current.style.display = 'none';
            this.contImgOk.current.style.display = 'flex';
            this.formBtn.current.disabled = false;
            setTimeout(() => {
              this.contImgOk.current.style.transform = 'scale(1)';
            }, 300);
          } else {
            this.setState({ error: res.data });
          }
        })
        .catch(() => {
          this.setState({ error: 'Something went wrong!' });
        });
    } else {
      this.formBtn.current.disabled = false;
    }
  }

  render() {
    const { form, error } = this.state;
    const { name, phone, email } = form;

    return (
      <section id="form-section" className="form-screen">
        <article>
          <h2>Let&rsquo;s start driving!</h2>
          <p>We would love to have you as part of our team, we want to meet you!</p>
          <div className="workers-container">
            <img src={Worker1} alt="worker" />
            <img src={Worker2} alt="worker" />
            <img src={Worker3} alt="worker" />
          </div>
        </article>
        <div className="img-ok-container" ref={this.contImgOk}>
          <img src={FormOk} alt="check" />
          <p className="text-img-ok">
            Thanks for applying please visit
            <br />
            <a target="_new" href="https://www.indeed.com/viewjob?jk=3eac0638886c804b&from=tp-serp&tk=1edt4odoh40gi800">
              our Indeed page here!
            </a>
          </p>
        </div>
        <form onSubmit={this.handleSubmit} ref={this.form}>
          <input
            name="name"
            type="text"
            className={name.error ? 'bad-input' : ''}
            value={name.value}
            onChange={this.handleChange}
            placeholder="Full name:"
          />
          {name.error ? <span>{name.error}</span> : ''}
          <input
            name="phone"
            type="tel"
            className={phone.error ? 'bad-input' : ''}
            value={phone.value}
            onChange={this.handleChange}
            placeholder="Phone number:"
          />
          {phone.error ? <span>{phone.error}</span> : ''}
          <input
            name="email"
            type="email"
            className={email.error ? 'bad-input' : ''}
            value={email.value}
            onChange={this.handleChange}
            placeholder="Email:"
          />
          {email.error ? <span>{email.error}</span> : ''}
          {error ? <span>{error}</span> : ''}
          <button type="submit" ref={this.formBtn}>Apply</button>
        </form>
      </section>
    );
  }
}

export default FormScreen;
