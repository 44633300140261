import React from 'react';
import Message from '../../assets/images/screen_2/message.svg';
import Worker from '../../assets/images/screen_2/worker.svg';
import ScrollBtn from '../shared/ScrollBtn';
import './style.scss';

const WorkerMessage = () => (
  <section className="worker-message">
    <div>
      <article>
        <h2>
          We are looking for drivers with the best attitude
          to join our team!
        </h2>
        <ScrollBtn />
      </article>
      <img src={Message} alt="worker" />
    </div>
    <img src={Worker} alt="worker" />
  </section>
);

export default WorkerMessage;
